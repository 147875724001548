export const LOADING = "LOADING";
export const SET_LOADING = "SET_LOADING";
export const CLEAR_STATE = "CLEAR_STATE";
export const REGISTRATION_CREATE_SUCCESS = "REGISTRATION_CREATE_SUCCESS";
export const REGISTRATION_UPDATE_SUCCESS = "REGISTRATION_UPDATE_SUCCESS";
export const REGISTRATION_ERROR = "REGISTRATION_ERROR";
export const AUTH_ERROR = "AUTH_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const METRICS_SUCCESS = "METRICS_SUCCESS";
export const METRICS_ERROR = "METRICS_ERROR";
export const LM_FETCH_SUCCESS = "LM_FETCH_SUCCESS";
export const LM_PENDING_FETCH_SUCCESS = "LM_PENDING_FETCH_SUCCESS";
export const LM_FETCH_ERROR = "LM_FETCH_ERROR";
export const LM_PENDING_FETCH_ERROR = "LM_PENDING_FETCH_ERROR";
export const AM_FETCH_SUCCESS = "AM_FETCH_SUCCESS";
export const AM_PENDING_FETCH_SUCCESS = "AM_PENDING_FETCH_SUCCESS";
export const AM_FETCH_ERROR = "AM_FETCH_ERROR";
export const AM_PENDING_FETCH_ERROR = "AM_PENDING_FETCH_ERROR";
export const MEMBERSHIP_DETAILS_FETCH_SUCCESS =
  "MEMBERSHIP_DETAILS_FETCH_SUCCESS";
export const MEMBERSHIP_DETAILS_FETCH_ERROR = "MEMBERSHIP_DETAILS_FETCH_ERROR";
export const PAYMENT_STATUS_UPDATE_SUCCESS = "PAYMENT_STATUS_UPDATE_SUCCESS";
export const PAYMENT_STATUS_UPDATE_ERROR = "PAYMENT_STATUS_UPDATE_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const PAYMENT_ORDER = "PAYMENT_ORDER";
export const PAYMENT_ERROR = "PAYMENT_ERROR";
export const PAYMENT_VERIFICATION = "PAYMENT_VERIFICATION";
export const EMAIL_SEND_SUCCESS = "EMAIL_SEND_SUCCESS";
export const EMAIL_SEND_FAILURE = "EMAIL_SEND_FAILURE";
export const TESTIMONIAL_CREATION_SUCCESS = "TESTIMONIAL_CREATION_SUCCESS";
export const TESTIMONIAL_CREATION_ERROR = "TESTIMONIAL_CREATION_ERROR";
export const EXPIRED_MEMBERSHIP_FETCHED_SUCCESS =
  "EXPIRED_MEMBERSHIP_FETCHED_SUCCESS";
export const EXPIRED_MEMBERSHIP_FETCHED_ERROR =
  "EXPIRED_MEMBERSHIP_FETCHED_ERROR";
export const RENEWED_MEMBERSHIP_FETCHED_SUCCESS =
  "RENEWED_MEMBERSHIP_FETCHED_SUCCESS";
export const RENEWED_MEMBERSHIP_FETCHED_ERROR =
  "RENEWED_MEMBERSHIP_FETCHED_ERROR";
export const EMAIL_UNSUBSCRIBED_SUCCESS = "EMAIL_UNSUBSCRIBED_SUCCESS";
export const EMAIL_UNSUBSCRIBED_ERROR = "EMAIL_UNSUBSCRIBED_ERROR";
export const JOBS_FETCHED_SUCCESS = "JOBS_FETCHED_SUCCESS";
export const JOBS_FETCHED_ERROR = "JOBS_FETCHED_ERROR";
export const CLEAR_JOB_STATUS = "CLEAR_JOB_STATUS";
export const RENEWAL_PROCESSED_SUCCESS = "RENEWAL_PROCESSED_SUCCESS";
export const RENEWAL_PROCESSED_ERROR = "RENEWAL_PROCESSED_ERROR";
export const EVENTS_SUCCESS = "EVENTS_SUCCESS";
export const EVENTS_FAILURE = "EVENTS_FAILURE";
export const UPCOMING_EVENTS = "UPCOMING_EVENTS";
export const COMPLETED_EVENTS = "COMPLETED_EVENTS";
export const DELETE_TEMP_USER = "DELETE_TEMP_USER";
